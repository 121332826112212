import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ขั้นตอนการทำงานของเรา",
      "style": {
        "position": "relative"
      }
    }}>{`ขั้นตอนการทำงานของเรา`}<a parentName="h1" {...{
        "href": "#%E0%B8%82%E0%B8%B1%E0%B9%89%E0%B8%99%E0%B8%95%E0%B8%AD%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%97%E0%B8%B3%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2",
        "aria-label": "ขั้นตอนการทำงานของเรา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
 ขั้นตอนสำคัญของแผนการดำเนินงาน ERP ไปใช้ในองค์กร
    </LeadParagraph>
    <p>{`การใช้ระบบการวางแผนทรัพยากรองค์กร (ERP) อาจเป็นงานที่ซับซ้อนซึ่งส่งผลกระทบต่อส่วนต่างๆ ของธุรกิจ เช่นเดียวกับความคิดริเริ่มที่สำคัญ แผนการดำเนินงานที่ออกแบบมาอย่างดีเป็นสิ่งสำคัญ การแบ่งการใช้งานของคุณออกเป็นช่วงๆ โดยแต่ละขั้นตอนมีเป้าหมายที่ชัดเจน สามารถเพิ่มความสำเร็จของคุณได้สูงสุด`}</p>
    <p>{`ในทางตรงกันข้าม การดำดิ่งสู่การนำERP ไปใช้โดยไม่ได้กำหนดทิศทาง ขอบเขต และโครงสร้างโครงการที่ชัดเจนก่อนจะเพิ่มโอกาสในการประสบปัญหาที่สำคัญในภายหลัง`}</p>
    <h2 {...{
      "id": "การนำ-erp-ไปใช้คืออะไร-",
      "style": {
        "position": "relative"
      }
    }}>{`การนำ ERP ไปใช้คืออะไร ?`}<a parentName="h2" {...{
        "href": "#%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%99%E0%B8%B3-erp-%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%84%E0%B8%B7%E0%B8%AD%E0%B8%AD%E0%B8%B0%E0%B9%84%E0%B8%A3-",
        "aria-label": "การนำ erp ไปใช้คืออะไร  permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`ระบบ ERP รวมฟังก์ชันมากมายทั่วทั้งธุรกิจ เช่น การจัดการด้านการเงิน ทรัพยากรบุคคล การขาย และการผลิต เพื่อส่งมอบผลประโยชน์ เช่น ผลผลิตและประสิทธิภาพที่เพิ่มขึ้น การใช้งาน ERP อธิบายกระบวนการ วางแผน กำหนดค่า และปรับ ใช้ERP กระบวนการมักจะดำเนินต่อไปสองสามเดือน—และซับซ้อน เนื่องจากระบบ ERP รองรับและทำให้ฟังก์ชันต่างๆ มากมายเป็นไปโดยอัตโนมัติ`}</p>
    <p>{`เพื่อให้แน่ใจว่าการใช้งานจะประสบความสำเร็จ องค์กรจำเป็นต้องกำหนดข้อกำหนดอย่างรอบคอบ กำหนดวิธีออกแบบกระบวนการใหม่เพื่อใช้ประโยชน์จากระบบ กำหนดค่าระบบ ERP เพื่อรองรับกระบวนการเหล่านั้น และทดสอบอย่างเข้มงวดก่อนที่จะปรับใช้กับผู้ใช้ การนำทางตามขั้นตอนทั้งหมดตามกำหนดเวลาที่ประสบความสำเร็จนั้นจำเป็นต้องมีการวางแผนอย่างรอบคอบและแนวทางการนำไปปฏิบัติที่มีโครงสร้างเป็นขั้นตอน`}</p>
    <h2 {...{
      "id": "erp-แบบ-opensoure-ใช้งานได้จริง-ใช้งานได้ฟรี-จริงหรือไม่-และทำไมต้องจ่าย-",
      "style": {
        "position": "relative"
      }
    }}>{`ERP แบบ Opensoure ใช้งานได้จริง ใช้งานได้ฟรี จริงหรือไม่ และทำไมต้องจ่าย ?`}<a parentName="h2" {...{
        "href": "#erp-%E0%B9%81%E0%B8%9A%E0%B8%9A-opensoure-%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%88%E0%B8%A3%E0%B8%B4%E0%B8%87-%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%9F%E0%B8%A3%E0%B8%B5-%E0%B8%88%E0%B8%A3%E0%B8%B4%E0%B8%87%E0%B8%AB%E0%B8%A3%E0%B8%B7%E0%B8%AD%E0%B9%84%E0%B8%A1%E0%B9%88-%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%97%E0%B8%B3%E0%B9%84%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%88%E0%B9%88%E0%B8%B2%E0%B8%A2-",
        "aria-label": "erp แบบ opensoure ใช้งานได้จริง ใช้งานได้ฟรี จริงหรือไม่ และทำไมต้องจ่าย  permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`ระบบอีอาร์พี แบบ Opensoure ปัจจุบันเริ่มได้รับความนิยมขึ้นมากเนื่องจากการยอมรับ สังคมแบบเปิดเผย Sourcecode เจริญเติบโตไวมากทำให้ สามารถพัฒนาฟังก็ชั่นการใช้งานได้เทียบเท่า หรือดีกว่าเมื่อเทียบกับซอฟแวร์แบบดั้งเดิมในระบบปิด ขณะที่ต้นทุนต่ำกว่ามาก สามารถนำไปใช้งานไม่มีปัญหาเรื่องไลเซนต์ ข้อดีคือทำให้องค์กรไม่ต้องเสี่ยงกับปัญหาซอฟแวร์ที่ผิดกฎหมาย สามารถใช้งานได้อย่างเต็มที่ ผลที่ได้คือต้นุทนค่าใช้จ่ายที่ลดลง เสียค่าใช้จ่ายเพียงแค่การ Implementation เท่านั้น ซึ่ง  ERPNext เป็นระบบอีอาร์พีที่กำลังได้รับความนิยมอย่างสูง มีผู้ใช้งานทั่วโลก คุณจึงมั่นใจได้ถึงประสิทธิภาพและความปลอดภัย`}</p>
    <h2 {...{
      "id": "ขั้นตอนของแผนการดำเนินงาน-erp-ของเรา",
      "style": {
        "position": "relative"
      }
    }}>{`ขั้นตอนของแผนการดำเนินงาน ERP ของเรา`}<a parentName="h2" {...{
        "href": "#%E0%B8%82%E0%B8%B1%E0%B9%89%E0%B8%99%E0%B8%95%E0%B8%AD%E0%B8%99%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%81%E0%B8%9C%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%94%E0%B8%B3%E0%B9%80%E0%B8%99%E0%B8%B4%E0%B8%99%E0%B8%87%E0%B8%B2%E0%B8%99-erp-%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%80%E0%B8%A3%E0%B8%B2",
        "aria-label": "ขั้นตอนของแผนการดำเนินงาน erp ของเรา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <Image size="M" src="/images/services/erpnext-impl-process.png" alt="Anndream Software" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h2 {...{
      "id": "4-ขั้นที่เรียบง่ายไม่ซับซ้อนประกอบด้วย",
      "style": {
        "position": "relative"
      }
    }}>{`4 ขั้นที่เรียบง่ายไม่ซับซ้อนประกอบด้วย:`}<a parentName="h2" {...{
        "href": "#4-%E0%B8%82%E0%B8%B1%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%9A%E0%B8%87%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%8B%E0%B8%B1%E0%B8%9A%E0%B8%8B%E0%B9%89%E0%B8%AD%E0%B8%99%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%81%E0%B8%AD%E0%B8%9A%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2",
        "aria-label": "4 ขั้นที่เรียบง่ายไม่ซับซ้อนประกอบด้วย permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ดำเนินการสำรวจความต้องการที่แท้จริงขององค์กร`}</strong>{`, ซึ่งขั้นตอนนี้จะทำให้ทราบถึงปัญหาที่แท้จริงขององค์กร ด้วยเครื่องมือที่เหมาะสม การสัมภาษณ์ ผู้เกี่ยวข้อง หัวหน้างาน แบบสำรวจ และเข้าดูกระบวนการทำงานที่ต้องการใช้ระบบอีอาร์พี`}</li>
      <li parentName="ul"><strong parentName="li">{`รายงานสรุปความต้องการและปัญหาขององค์กรในส่วนที่ใช้งานระบบอีอาร์พี`}</strong>{`, ด้วยขั้นตอนนี้จะทำให้องค์กรทราบปัญหาและความจำเป็นที่แท้จริงเพื่อนำระบบอีอาร์พีเข้ามาแก้ปัญหา`}</li>
      <li parentName="ul"><strong parentName="li">{`สรุปผลและกำหนดแผนการทำงานร่วมกันเพื่อให้ทราบถึงกรอบการทำงานรวมถึงค่าใช้จ่ายที่เกิดขึ้น`}</strong>{`, ขั้นตอนนี้ทำให้องค์กรสามารถทราบถึงค่าใช้จ่ายที่จำเป็นและแผนการทำงาน ซึ่งส่วนใหญ่จะอยู่ในช่วง 3 - 6 เดือนสำหรับองค์กรทั่วๆไป ยกเว้นองค์กรขนาดใหญ่อาจจะต้องใช้เวลามากขึ้นตามขนาดและความซับซ้อนของธุรกิจ`}</li>
      <li parentName="ul"><strong parentName="li">{`การ Implementation`}</strong>{`, ขั้นตอนนี้คือการนำความต้องการมาพัฒนาในระบบอีอาร์พี อาจจะมีส่วนงานที่ต้องทำขนานกับระบบเดิมอยู่บางส่วนเพื่อความรวดเร็วในการส่งมอบงาน`}</li>
    </ul>
    <h2 {...{
      "id": "ราคา",
      "style": {
        "position": "relative"
      }
    }}>{`ราคา`}<a parentName="h2" {...{
        "href": "#%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2",
        "aria-label": "ราคา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h5 {...{
      "id": "-แก้ใขรายงานและแบบฟอร์มตามรูปแบบของบริษัท-ราคาเริ่มต้นที่-5000---25000-บาท-ต่อรายงานแบบฟอร์ม",
      "style": {
        "position": "relative"
      }
    }}>{`-แก้ใขรายงานและแบบฟอร์มตามรูปแบบของบริษัท ราคาเริ่มต้นที่ 5,000 - 25,000 บาท ต่อรายงาน/แบบฟอร์ม`}<a parentName="h5" {...{
        "href": "#-%E0%B9%81%E0%B8%81%E0%B9%89%E0%B9%83%E0%B8%82%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%9F%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%A1%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%A3%E0%B8%B9%E0%B8%9B%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-5000---25000-%E0%B8%9A%E0%B8%B2%E0%B8%97-%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B8%9F%E0%B8%AD%E0%B8%A3%E0%B9%8C%E0%B8%A1",
        "aria-label": " แก้ใขรายงานและแบบฟอร์มตามรูปแบบของบริษัท ราคาเริ่มต้นที่ 5000   25000 บาท ต่อรายงานแบบฟอร์ม permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <h5 {...{
      "id": "-อบรมการใช้งานเบื้องต้นในสถานที่-35000-บาทวัน-ไม่เกิน-3-คน-หรือ-อบรมออนไลน์-10000-บาทวัน",
      "style": {
        "position": "relative"
      }
    }}>{`-อบรมการใช้งานเบื้องต้นในสถานที่ 35,000 บาท/วัน ไม่เกิน 3 คน (หรือ อบรมออนไลน์ 10,000 บาท/วัน)`}<a parentName="h5" {...{
        "href": "#-%E0%B8%AD%E0%B8%9A%E0%B8%A3%E0%B8%A1%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%9A%E0%B8%B7%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%95%E0%B9%89%E0%B8%99%E0%B9%83%E0%B8%99%E0%B8%AA%E0%B8%96%E0%B8%B2%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-35000-%E0%B8%9A%E0%B8%B2%E0%B8%97%E0%B8%A7%E0%B8%B1%E0%B8%99-%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B9%80%E0%B8%81%E0%B8%B4%E0%B8%99-3-%E0%B8%84%E0%B8%99-%E0%B8%AB%E0%B8%A3%E0%B8%B7%E0%B8%AD-%E0%B8%AD%E0%B8%9A%E0%B8%A3%E0%B8%A1%E0%B8%AD%E0%B8%AD%E0%B8%99%E0%B9%84%E0%B8%A5%E0%B8%99%E0%B9%8C-10000-%E0%B8%9A%E0%B8%B2%E0%B8%97%E0%B8%A7%E0%B8%B1%E0%B8%99",
        "aria-label": " อบรมการใช้งานเบื้องต้นในสถานที่ 35000 บาทวัน ไม่เกิน 3 คน หรือ อบรมออนไลน์ 10000 บาทวัน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <h5 {...{
      "id": "-จ้างเป็นที่ปรึกษาของโปรเจค-ราคาเริ่มต้นที่-35000-บาท-เดือน",
      "style": {
        "position": "relative"
      }
    }}>{`-จ้างเป็นที่ปรึกษาของโปรเจค ราคาเริ่มต้นที่ 35,000 บาท /เดือน`}<a parentName="h5" {...{
        "href": "#-%E0%B8%88%E0%B9%89%E0%B8%B2%E0%B8%87%E0%B9%80%E0%B8%9B%E0%B9%87%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%9B%E0%B8%A3%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B9%82%E0%B8%9B%E0%B8%A3%E0%B9%80%E0%B8%88%E0%B8%84-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88-35000-%E0%B8%9A%E0%B8%B2%E0%B8%97-%E0%B9%80%E0%B8%94%E0%B8%B7%E0%B8%AD%E0%B8%99",
        "aria-label": " จ้างเป็นที่ปรึกษาของโปรเจค ราคาเริ่มต้นที่ 35000 บาท เดือน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <h5 {...{
      "id": "-ค่า-implementation-ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ",
      "style": {
        "position": "relative"
      }
    }}>{`-ค่า Implementation ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ`}<a parentName="h5" {...{
        "href": "#-%E0%B8%84%E0%B9%88%E0%B8%B2-implementation-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%82%E0%B8%B6%E0%B9%89%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%A1%E0%B8%B4%E0%B8%99%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88",
        "aria-label": " ค่า implementation ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h5>
    <Image size="M" src="/images/services/mycustomer.svg" alt="Anndream Software Customers" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h4 {...{
      "id": "ง่ายครบ-จบในที่เดียว-ด้วยประสบการณ์กว่า-10-ปี-คุณมั่นใจ-ได้งานเร็ว-ไม่ทิ้งงาน-มีการดูแลต่อเนื่อง",
      "style": {
        "position": "relative"
      }
    }}>{`ง่ายครบ จบในที่เดียว ด้วยประสบการณ์กว่า 10 ปี คุณมั่นใจ ได้งานเร็ว ไม่ทิ้งงาน มีการดูแลต่อเนื่อง`}<a parentName="h4" {...{
        "href": "#%E0%B8%87%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A3%E0%B8%9A-%E0%B8%88%E0%B8%9A%E0%B9%83%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%94%E0%B8%B5%E0%B8%A2%E0%B8%A7-%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%AA%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B2-10-%E0%B8%9B%E0%B8%B5-%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B8%A1%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%83%E0%B8%88-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B9%87%E0%B8%A7-%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%97%E0%B8%B4%E0%B9%89%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99-%E0%B8%A1%E0%B8%B5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%94%E0%B8%B9%E0%B9%81%E0%B8%A5%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%99%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87",
        "aria-label": "ง่ายครบ จบในที่เดียว ด้วยประสบการณ์กว่า 10 ปี คุณมั่นใจ ได้งานเร็ว ไม่ทิ้งงาน มีการดูแลต่อเนื่อง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "สนใจพัฒนานำระบบอีาร์พีไปใช้งาน-สามารถปรึกษาได้ฟรี-ติดต่ออีเมล์-anndreamcomgmailcom",
      "style": {
        "position": "relative"
      }
    }}>{`สนใจพัฒนานำระบบอีาร์พีไปใช้งาน สามารถปรึกษาได้ฟรี ติดต่ออีเมล์ `}<a parentName="h4" {...{
        "href": "mailto:anndream.com@gmail.com"
      }}>{`anndream.com@gmail.com`}</a><a parentName="h4" {...{
        "href": "#%E0%B8%AA%E0%B8%99%E0%B9%83%E0%B8%88%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2%E0%B8%99%E0%B8%B3%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AD%E0%B8%B5%E0%B8%B2%E0%B8%A3%E0%B9%8C%E0%B8%9E%E0%B8%B5%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99-%E0%B8%AA%E0%B8%B2%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%9B%E0%B8%A3%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%9F%E0%B8%A3%E0%B8%B5-%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%AD%E0%B8%B5%E0%B9%80%E0%B8%A1%E0%B8%A5%E0%B9%8C-anndreamcomgmailcom",
        "aria-label": "สนใจพัฒนานำระบบอีาร์พีไปใช้งาน สามารถปรึกษาได้ฟรี ติดต่ออีเมล์ anndreamcomgmailcom permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      